import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/api/auth": [4],
		"/dashboard": [5,[],[2]],
		"/dashboard/location-agent": [8,[],[2]],
		"/dashboard/payments/plans": [~9,[],[2]],
		"/dashboard/payments/plans/[id]": [~10,[],[2]],
		"/dashboard/[type]": [~6,[],[2]],
		"/dashboard/[type]/[uid]": [~7,[],[2]],
		"/signin": [11],
		"/signup": [12]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';